@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background: #ffffff, default;
  position: relative;
}

:root {
  --bgcolor: #ffffff;
  --primary-color: #ffffff;
  --text-color: #828286;
  --main-text-color: #333333;
}

.navbar-light .navbar-brand {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 4px;
}

.navbar-brand {
  position: relative;
  width: 170px;
  left: 15px;
  max-height: 70px;
  /* height of the navbar */
}

.navbar li {
  padding: 0 1rem;
}

.navbar li a {
  font-size: 1.2rem;
  position: relative;
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgb(0, 0, 0);
}

.navbar-light .navbar-nav .nav-link {
  color: rgb(0, 0, 0);
}

section {
  padding-top: 2rem;
  padding-bottom: 6rem;
}

.lnr {
  display: inline-block;
  fill: currentColor;
  width: 1rem;
  height: 1rem;
  vertical-align: -0.05em;
  stroke-width: 1;
}

.services-icon {
  margin-bottom: 20px;
  font-size: 30px;
}

.displaysize {
  font-size: 4rem;
  font-weight: 300;
  line-height: 1.2;
}

bgc2,
.vLine,
.hLine {
  background-color: #f8f8f8;
}

.quote-icon {
  font-size: 40px;
  margin-bottom: 20px;
}

.services-icon {
  font-size: 60px;
  margin-left: 2rem;
}

.navbar li a:hover {
  color: blue;
  text-shadow: 0 0.2rem 0.3rem rgb(52, 42, 110);
}

/*Fixed Header Image Start*/

html,
body {
  margin: 0px;
  padding: 0px;
}

.main-hero-para {
  font-size: 20px;
  text-align: left;
}

.main-heading {
  font-size: 35px;
  text-align: left;
}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

header {
  z-index: 1;
  position: fixed;
  width: 100%;
  height: 60px;
  background: rgb(255, 255, 255);
}

h1.size-text {
  font-size: 3.5rem;
  color: white;
}

h1 {
  font-size: 3.5rem;
  color: white;
}

#hero1 {
  background: url(./pages/Homepage/components/Images/AWLogo.jpg);
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-color: #000000d7;
}

.hero,
.content {
  text-align: center;
  position: relative;
  width: 100%;
}

.inner {
  min-height: 500px;
  position: relative;
}

.size-text {
  margin: 0px;
  font-size: 20px;
}

@media (max-width:500px) {

  .inner {
    min-height: 380px;
    position: relative;
  }

  .size-text {
    margin: 0px;
    font-size: 16px;
  }
}

/*Fixed Header Image Close*/


/*About Us Page Started */

body {
  font-family: "Roboto";
  font-size: 24px;
}

.aboutus-section {
  padding: 100px 0;
  margin-bottom: 140px;
}

@media (max-width:500px) {

  .aboutus-section {
    padding: 100px 0;
    margin-bottom: 40px;
  }
}

.aboutus-title {
  font-size: 40px;
  letter-spacing: 0;
  line-height: 42px;
  margin: 0 0 39px;
  margin-left: 38px;
  padding: 0 0 11px;
  position: relative;
  text-transform: uppercase;
  color: #000;
}

.aboutus-title::after {
  background: #fdb801 none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  width: 54px;
}

.aboutus-text {
  color: #606060;
  font-size: 18px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  line-height: 25px;
  margin: 0 0 20px;
  margin-left: 22px;
  text-align: left;
  font-family: cursive;
  margin-left: 40px;
  margin-right: 40px;
  text-align: justify;
}

a:hover,
a:active {
  color: #ffb901;
  text-decoration: none;
  outline: 0;
}

.background-color {
  background-color: rgb(255, 255, 255);
  margin-top: -7%;
  margin-bottom: -16%;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-left: 4px;
  margin-right: 4px;
}

@media screen and (max-width: 500px) {
  .freetrialbackground-color {
    background-color: rgb(255, 255, 255);
    margin-top: -47%;
    margin-bottom: -58%;
    padding-top: 100px;
    padding-bottom: 100px;
    margin-left: 4px;
    margin-right: 4px;
  }

  .pricingbackground-color {
    background-color: rgb(255, 255, 255);
    margin-top: -20%;
    margin-bottom: 0%;
  }

  .ourClientsbackground-color {
    background-color: rgb(255, 255, 255);
    margin-top: -20%;
    margin-bottom: 0%;
  }
}



/*Footer Section Start */

footer {
  padding: 1rem 0;
  background-color: #182334;
  color: #fff;
}

footer h2 {
  color: #fff;
}

footer li {
  list-style: none;
  font-size: 0.8rem;
  font-weight: lighter;
  color: #fff;
  cursor: pointer;
  margin: 1rem 0;
}

footer li:hover {
  color: var(--primary-color);
}

footer .fontawesome-style {
  font-size: 1.4rem;
  color: var(--primary-color);
  background-color: transparent;
  width: 0rem;
  height: 0rem;
  margin: 1rem 0;
  cursor: pointer;
}

footer .fontawesome-style:hover {
  transition: all 1s;
  animation: rotatefont 1s linear infinite alternate-reverse;
  color: #fff;
}

@keyframes rotatefont {
  0% {
    transform: scale(0.6);
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    -ms-transform: scale(0.6);
    -o-transform: scale(0.6);
  }

  100% {
    transform: scale(1.2);
  }
}

footer .main-hero-para {
  margin: 0;
}

.p {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
}

/* --------------------------------- Contact US Section starts  
                        -------------------------------------------------------*/
.contactus-section {
  height: fit-content;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: white;
  border-color: rgb(148, 10, 10);
  border-style: line;
  border-width: 2px;
}

.contact-input-feild {
  margin-bottom: 1rem;
}

/* --------------------------------- Contact US Section ends  
                                                                        -------------------------------------------------------*/


/** tailwind Css */

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}

.bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 213, 255, var(--tw-bg-opacity));
}

.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(168, 85, 247, var(--tw-bg-opacity));
}

.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}

.bg-lightBlue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(186, 230, 253, var(--tw-bg-opacity));
}

.bg-lightBlue-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(125, 211, 252, var(--tw-bg-opacity));
}

.bg-lightBlue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(56, 189, 248, var(--tw-bg-opacity));
}

.bg-lightBlue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(14, 165, 233, var(--tw-bg-opacity));
}

.bg-lightBlue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 132, 199, var(--tw-bg-opacity));
}

.bg-lightBlue-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(7, 89, 133, var(--tw-bg-opacity));
}

.bg-lightBlue-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(12, 74, 110, var(--tw-bg-opacity));
}

.bg-teal-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 246, 228, var(--tw-bg-opacity));
}

.bg-teal-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 184, 166, var(--tw-bg-opacity));
}

.bg-emerald-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}

.bg-emerald-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}

.bg-emerald-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 179, 8, var(--tw-bg-opacity));
}

.bg-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 215, 170, var(--tw-bg-opacity));
}

.bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 115, 22, var(--tw-bg-opacity));
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}

.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}

.bg-blueGray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 250, 252, var(--tw-bg-opacity));
}

.bg-blueGray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
}

.bg-blueGray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}

.bg-blueGray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(71, 85, 105, var(--tw-bg-opacity));
}

.bg-blueGray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(51, 65, 85, var(--tw-bg-opacity));
}

.bg-blueGray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 41, 59, var(--tw-bg-opacity));
}

.active\:bg-indigo-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}

.active\:bg-lightBlue-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 132, 199, var(--tw-bg-opacity));
}

.active\:bg-blueGray-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 250, 252, var(--tw-bg-opacity));
}

.active\:bg-blueGray-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(71, 85, 105, var(--tw-bg-opacity));
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-cover {
  background-size: cover;
}

.bg-full {
  background-size: 100%;
}

.border-collapse {
  border-collapse: collapse;
}

.border-lightBlue-700 {
  --tw-border-opacity: 1;
  border-color: rgba(3, 105, 161, var(--tw-border-opacity));
}

.border-blueGray-50 {
  --tw-border-opacity: 1;
  border-color: rgba(248, 250, 252, var(--tw-border-opacity));
}

.border-blueGray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(241, 245, 249, var(--tw-border-opacity));
}

.border-blueGray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(226, 232, 240, var(--tw-border-opacity));
}

.border-blueGray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(203, 213, 225, var(--tw-border-opacity));
}

.border-blueGray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(100, 116, 139, var(--tw-border-opacity));
}

.border-blueGray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(71, 85, 105, var(--tw-border-opacity));
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}

.border-0 {
  border-width: 0px;
}

.border-2 {
  border-width: 2px;
}

.border {
  border-width: 1px;
}

.border-t-0 {
  border-top-width: 0px;
}

.border-r-0 {
  border-right-width: 0px;
}

.border-l-0 {
  border-left-width: 0px;
}

.border-t {
  border-top-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.cursor-pointer {
  cursor: pointer;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: -webkit-flex;
  display: flex;
}

.inline-flex {
  display: -webkit-inline-flex;
  display: inline-flex;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.flex-row {
  -webkit-flex-direction: row;
  flex-direction: row;
}

.flex-col {
  -webkit-flex-direction: column;
  flex-direction: column;
}

.flex-wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.items-center {
  -webkit-align-items: center;
  align-items: center;
}

.items-stretch {
  -webkit-align-items: stretch;
  align-items: stretch;
}

.content-center {
  -webkit-align-content: center;
  align-content: center;
}

.justify-end {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.justify-center {
  -webkit-justify-content: center;
  justify-content: center;
}

.justify-between {
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.flex-1 {
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
}

.flex-auto {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}

.flex-initial {
  -webkit-flex: 0 1 auto;
  flex: 0 1 auto;
}

.flex-grow {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

.float-left {
  float: left;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.h-0 {
  height: 0px;
}

.h-2 {
  height: 0.5rem;
}

.h-5 {
  height: 1.25rem;
}

.h-8 {
  height: 2rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-20 {
  height: 5rem;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.h-95-px {
  height: 95px;
}

.h-70-px {
  height: 70px;
}

.h-350-px {
  height: 350px;
}

.h-500-px {
  height: 500px;
}

.h-600-px {
  height: 600px;
}

.text-55 {
  font-size: 55rem;
}

.text-xs {
  font-size: 0.95rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.975rem;
  line-height: 1.25rem;
}

.text-sml {
  font-size: 1.375rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}


.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.leading-none {
  line-height: 1;
}

.leading-snug {
  line-height: 1.375;
}

.leading-normal {
  line-height: 1.5;
}

.leading-relaxed {
  line-height: 1.625;
}

.list-none {
  list-style-type: none;
}

.m-2 {
  margin: 0.5rem;
}

.m-4 {
  margin: 1rem;
}

.-m-16 {
  margin: -4rem;
}

.-m-24 {
  margin: -6rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-0 {
  margin-top: 0px;
}

.mr-0 {
  margin-right: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mt-48 {
  margin-top: 12rem;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.-ml-4 {
  margin-left: -1rem;
}

.-mt-20 {
  margin-top: -5rem;
}

.-ml-20 {
  margin-left: -5rem;
}

.-mt-24 {
  margin-top: -6rem;
}

.-mt-32 {
  margin-top: -8rem;
}

.-mt-48 {
  margin-top: -12rem;
}

.-mt-64 {
  margin-top: -16rem;
}

.last\:mr-0:last-child {
  margin-right: 0px;
}

.hover\:-mt-4:hover {
  margin-top: -1rem;
}

.max-h-860-px {
  max-height: 860px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-100-px {
  max-width: 100px;
}

.max-w-120-px {
  max-width: 120px;
}

.max-w-150-px {
  max-width: 150px;
}

.max-w-180-px {
  max-width: 180px;
}

.max-w-200-px {
  max-width: 200px;
}

.max-w-210-px {
  max-width: 210px;
}

.max-w-580-px {
  max-width: 580px;
}

.min-h-screen {
  min-height: 100vh;
}

.min-h-screen-75 {
  min-height: 75vh;
}

.min-w-0 {
  min-width: 0px;
}

.min-w-48 {
  min-width: 12rem;
}

.min-w-140-px {
  min-width: 140px;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-80 {
  opacity: .8;
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-8 {
  padding: 2rem;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.pt-0 {
  padding-top: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pb-40 {
  padding-bottom: 10rem;
}

.pt-48 {
  padding-top: 12rem;
}

.pb-48 {
  padding-bottom: 12rem;
}

.pb-64 {
  padding-bottom: 16rem;
}

.placeholder-blueGray-300::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(203, 213, 225, var(--tw-placeholder-opacity));
}

.placeholder-blueGray-300:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(203, 213, 225, var(--tw-placeholder-opacity));
}

.placeholder-blueGray-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(203, 213, 225, var(--tw-placeholder-opacity));
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.top-0 {
  top: 0px;
}

.right-0 {
  right: 0px;
}

.bottom-0 {
  bottom: 0px;
}

.left-0 {
  left: 0px;
}

.top-auto {
  top: auto;
}

.bottom-auto {
  bottom: auto;
}

.left-auto {
  left: auto;
}

.-right-100 {
  right: -100%;
}

.-top-225-px {
  top: -225px;
}

.-top-160-px {
  top: -160px;
}

.-top-150-px {
  top: -150px;
}

.-top-94-px {
  top: -94px;
}

.-left-50-px {
  left: -50px;
}

.-top-29-px {
  top: -29px;
}

.-left-20-px {
  left: -20px;
}

.top-25-px {
  top: 25px;
}

.left-40-px {
  left: 40px;
}

.top-95-px {
  top: 95px;
}

.left-145-px {
  left: 145px;
}

.left-195-px {
  left: 195px;
}

.top-210-px {
  top: 210px;
}

.left-260-px {
  left: 260px;
}

* {
  --tw-shadow: 0 0 #0000;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

* {
  --tw-ring-inset: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.fill-current {
  fill: currentColor;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-pink-400 {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}

.text-lightBlue-300 {
  --tw-text-opacity: 1;
  color: rgba(125, 211, 252, var(--tw-text-opacity));
}

.text-lightBlue-400 {
  --tw-text-opacity: 1;
  color: rgba(56, 189, 248, var(--tw-text-opacity));
}

.text-lightBlue-500 {
  --tw-text-opacity: 1;
  color: rgba(14, 165, 233, var(--tw-text-opacity));
}

.text-lightBlue-600 {
  --tw-text-opacity: 1;
  color: rgba(2, 132, 199, var(--tw-text-opacity));
}

.text-teal-500 {
  --tw-text-opacity: 1;
  color: rgba(20, 184, 166, var(--tw-text-opacity));
}

.text-emerald-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgba(249, 115, 22, var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.text-blueGray-100 {
  --tw-text-opacity: 1;
  color: rgba(241, 245, 249, var(--tw-text-opacity));
}

.text-blueGray-200 {
  --tw-text-opacity: 1;
  color: rgba(226, 232, 240, var(--tw-text-opacity));
}

.text-blueGray-300 {
  --tw-text-opacity: 1;
  color: rgba(203, 213, 225, var(--tw-text-opacity));
}

.text-blueGray-400 {
  --tw-text-opacity: 1;
  color: rgba(148, 163, 184, var(--tw-text-opacity));
}

.text-blueGray-500 {
  --tw-text-opacity: 1;
  color: rgba(100, 116, 139, var(--tw-text-opacity));
}

.text-blueGray-600 {
  --tw-text-opacity: 1;
  color: rgba(71, 85, 105, var(--tw-text-opacity));
}

.text-blueGray-700 {
  --tw-text-opacity: 1;
  color: rgba(51, 65, 85, var(--tw-text-opacity));
}

.text-blueGray-800 {
  --tw-text-opacity: 1;
  color: rgba(30, 41, 59, var(--tw-text-opacity));
}

.hover\:text-lightBlue-600:hover {
  --tw-text-opacity: 1;
  color: rgba(2, 132, 199, var(--tw-text-opacity));
}

.hover\:text-blueGray-300:hover {
  --tw-text-opacity: 1;
  color: rgba(203, 213, 225, var(--tw-text-opacity));
}

.hover\:text-blueGray-500:hover {
  --tw-text-opacity: 1;
  color: rgba(100, 116, 139, var(--tw-text-opacity));
}

.hover\:text-blueGray-700:hover {
  --tw-text-opacity: 1;
  color: rgba(51, 65, 85, var(--tw-text-opacity));
}

.hover\:text-blueGray-800:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 41, 59, var(--tw-text-opacity));
}

.uppercase {
  text-transform: uppercase;
}

.no-underline {
  text-decoration: none;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tracking-wide {
  letter-spacing: 0.025em;
}

.align-middle {
  vertical-align: middle;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-words {
  overflow-wrap: break-word;
}

.w-5 {
  width: 1.25rem;
}

.w-8 {
  width: 2rem;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-auto {
  width: auto;
}

.w-1\/2 {
  width: 50%;
}

.w-6\/12 {
  width: 50%;
}

.w-10\/12 {
  width: 83.333333%;
}

.w-full {
  width: 100%;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-10 {
  z-index: 10;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.ease-linear {
  transition-timing-function: linear;
}

.duration-150 {
  transition-duration: 150ms;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes ping {

  75%,
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes ping {

  75%,
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@-webkit-keyframes bounce {

  0%,
  100% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    -webkit-transform: none;
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

@keyframes bounce {

  0%,
  100% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    -webkit-transform: none;
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

@media (min-width: 640px) {
  .sm\:block {
    display: block;
  }

  .sm\:mt-0 {
    margin-top: 0px;
  }

  .sm\:ml-1 {
    margin-left: 0.25rem;
  }

  .sm\:mr-2 {
    margin-right: 0.5rem;
  }

  .sm\:pt-0 {
    padding-top: 0px;
  }

  .sm\:w-6\/12 {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .md\:block {
    display: block;
  }

  .md\:flex {
    display: -webkit-flex;
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:flex-row {
    -webkit-flex-direction: row;
    flex-direction: row;
  }

  .md\:flex-col {
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .md\:flex-nowrap {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .md\:items-stretch {
    -webkit-align-items: stretch;
    align-items: stretch;
  }

  .md\:justify-start {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }

  .md\:justify-end {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }

  .md\:justify-between {
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .md\:mt-0 {
    margin-top: 0px;
  }

  .md\:mt-4 {
    margin-top: 1rem;
  }

  .md\:mb-4 {
    margin-bottom: 1rem;
  }

  .md\:mt-40 {
    margin-top: 10rem;
  }

  .md\:mt-64 {
    margin-top: 16rem;
  }

  .md\:ml-64 {
    margin-left: 16rem;
  }

  .md\:min-h-full {
    min-height: 100%;
  }

  .md\:min-w-full {
    min-width: 100%;
  }

  .md\:opacity-100 {
    opacity: 1;
  }

  .md\:overflow-hidden {
    overflow: hidden;
  }

  .md\:overflow-y-auto {
    overflow-y: auto;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:pt-0 {
    padding-top: 0px;
  }

  .md\:pb-2 {
    padding-bottom: 0.5rem;
  }

  .md\:pr-12 {
    padding-right: 3rem;
  }

  .md\:pt-32 {
    padding-top: 8rem;
  }

  .md\:fixed {
    position: fixed;
  }

  .md\:relative {
    position: relative;
  }

  .md\:top-0 {
    top: 0px;
  }

  .md\:bottom-0 {
    bottom: 0px;
  }

  .md\:left-0 {
    left: 0px;
  }

  .md\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:w-64 {
    width: 16rem;
  }

  .md\:w-4\/12 {
    width: 33.333333%;
  }

  .md\:w-5\/12 {
    width: 41.666667%;
  }

  .md\:w-6\/12 {
    width: 50%;
  }

  .md\:w-8\/12 {
    width: 66.666667%;
  }
}

@media (min-width: 1024px) {
  .lg\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:flex {
    display: -webkit-flex;
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:flex-row {
    -webkit-flex-direction: row;
    flex-direction: row;
  }

  .lg\:self-center {
    -webkit-align-self: center;
    align-self: center;
  }

  .lg\:justify-start {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }

  .lg\:order-1 {
    -webkit-order: 1;
    order: 1;
  }

  .lg\:order-2 {
    -webkit-order: 2;
    order: 2;
  }

  .lg\:order-3 {
    -webkit-order: 3;
    order: 3;
  }

  .lg\:mb-0 {
    margin-bottom: 0px;
  }

  .lg\:mr-1 {
    margin-right: 0.25rem;
  }

  .lg\:mr-4 {
    margin-right: 1rem;
  }

  .lg\:mt-16 {
    margin-top: 4rem;
  }

  .lg\:ml-auto {
    margin-left: auto;
  }

  .lg\:-ml-16 {
    margin-left: -4rem;
  }

  .lg\:-mt-64 {
    margin-top: -16rem;
  }

  .lg\:p-10 {
    padding: 2.5rem;
  }

  .lg\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:pt-0 {
    padding-top: 0px;
  }

  .lg\:pt-4 {
    padding-top: 1rem;
  }

  .lg\:pt-12 {
    padding-top: 3rem;
  }

  .lg\:pt-24 {
    padding-top: 6rem;
  }

  .lg\:pb-64 {
    padding-bottom: 16rem;
  }

  .lg\:static {
    position: static;
  }

  .lg\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-right {
    text-align: right;
  }

  .lg\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .lg\:text-blueGray-200 {
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }

  .lg\:hover\:text-blueGray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-3\/12 {
    width: 25%;
  }

  .lg\:w-4\/12 {
    width: 33.333333%;
  }

  .lg\:w-6\/12 {
    width: 50%;
  }

  .lg\:w-8\/12 {
    width: 66.666667%;
  }

  .lg\:w-9\/12 {
    width: 75%;
  }
}

@media (min-width: 1280px) {
  .xl\:mb-0 {
    margin-bottom: 0px;
  }

  .xl\:w-3\/12 {
    width: 25%;
  }

  .xl\:w-4\/12 {
    width: 33.333333%;
  }

  .xl\:w-6\/12 {
    width: 50%;
  }

  .xl\:w-8\/12 {
    width: 66.666667%;
  }
}

@media (min-width: 1536px) {}





.Principlesbgcolor {
  background-color: rgb(255, 255, 255);
}





/* Modal */
.overlay {
  /* background-color: rgba(0, 0, 0, 0.5); */
  position: fixed;
  width: 100%;
  height: 100%;
}

.modalContainer {
  max-width: 700px;
  width: 100%;
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  background-color: #ffffff;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
}

.imagespop {
  width: 250px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.modalRight {
  width: 100%;
}

.closeBtn {
  position: fixed;
  top: 8px;
  right: 8px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 3rem;
  padding: 1rem 2rem;
}

.btnContainer {
  display: flex;
  padding: 1rem 1rem;
}

.btnContainer button {
  width: 100%;
  margin: .5rem;
  padding: 16px 0;
  /* border: none; */
  border: 1px solid #411b57;
  /* color: #ffffff; */
}

.btnPrimary {
  background-color: #411b57;
  color: white;
}

.btnOutline {
  /* background-color: #a76a99; */
  background-color: white;
  color: #411b57;
}

.bold {
  font-weight: 600;
}

@media screen and (max-width: 500px) {
  .modalContainer {
    flex-direction: column;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
    height: 100vh;
  }

  img {
    width: 100%;
    max-height: 70vh;
    object-fit: cover;
  }

  .heading {
    margin: 1rem;
  }
}


button.contact_form_submit {
  background: linear-gradient(to top right, #1325e8 -5%, #8f10b7 100%);
  border: none;
  color: #fff;
  padding: 10px 15px;
  width: 100%;
  margin-top: 5px;
  border-radius: 35px;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 2px;
}



@media (max-width:600px) {
  .overlay {
    /* background-color: rgba(0, 0, 0, 0.5); */
    position: fixed;
    width: 100%;
    height: 100%;
  }

  .modalContainer {
    max-width: 800px;
    width: 100%;
    position: fixed;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    background-color: #ffffff;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
    border-radius: 8px;
  }

  .imagespop {
    width: 250px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .modalRight {
    width: 100%;
  }

  .closeBtn {
    position: fixed;
    top: 8px;
    right: 8px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 3rem;
    padding: 1rem 2rem;
  }

  .btnContainer {
    display: flex;
    padding: 1rem 1rem;
  }

  .btnContainer button {
    width: 100%;
    margin: .5rem;
    padding: 16px 0;
    /* border: none; */
    border: 1px solid #411b57;
    /* color: #ffffff; */
  }

  .btnPrimary {
    background-color: #411b57;
    color: white;
  }

  .btnOutline {
    /* background-color: #a76a99; */
    background-color: white;
    color: #411b57;
  }

  .bold {
    font-weight: 600;
  }
}

@media (max-width:490px) {
  .overlay {
    /* background-color: rgba(0, 0, 0, 0.5); */
    position: fixed;
    width: 50%;
    height: 30%;
  }

  .modalContainer {
    max-width: 500px;
    max-height: 600px;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    background-color: #ffffff;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
    border-radius: 8px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }

  .imagespop {
    width: 0px;
  }

  .modalRight {
    width: 100%;
  }

  .closeBtn {
    position: fixed;
    top: 8px;
    right: 8px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 1rem;
    padding: 0.5rem 1.2rem;
  }

  .btnContainer {
    display: flex;
    padding: 0.5rem 1rem;
  }

  .btnContainer button {
    width: 100%;
    margin: .5rem;
    padding: 16px 0;
    /* border: none; */
    border: 1px solid #411b57;
    /* color: #ffffff; */
  }

  .btnPrimary {
    background-color: #411b57;
    color: white;
  }

  .btnOutline {
    /* background-color: #a76a99; */
    background-color: white;
    color: #411b57;
  }

  .bold {
    font-weight: 600;
  }
}


.wrapper {
  width: 100%;
  overflow: hidden;
}

.photobanner {
  position: relative;
  height: 233px;
  margin-bottom: 30px;
  display: flex;
  width: 100%;
}

.photobanner img {
  margin: 0px 25px;
  box-shadow: 2px 2px 8px #8a8a8a;
}

.photobanner {
  animation: bannermove 20s linear infinite alternate-reverse;
  -webkit-animation: bannermove 20s linear infinite alternate-reverse;
}

@keyframes bannermove {
  from {
    left: 0px;
  }

  to {
    left: -1700px;
  }
}

/* -------Pricing -----*/


.pricing .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: .1rem;
  font-weight: bold;
  padding: 1rem;
  opacity: 0.7;
  transition: all 0.2s;
}

/* Hover Effects on Card */

@media (min-width: 992px) {
  .pricing .card:hover {
    margin-top: -.25rem;
    margin-bottom: .25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
  }

  .pricing .card:hover .btn {
    opacity: 1;
  }
}


.title {
  margin-bottom: 20px;
  padding: 20px
}

.img {
  height: 260px;
  width: 100%
}


@media (max-width:490px) {
  .img {
    height: 140px;
    width: 60%
  }

  .text-xl {
    font-size: 1.1rem;
  }
}



.pricing-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.pricing-item {
  padding: 24px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pricing-item.featured {
  background: #0B0641;
  border-radius: 20px;
  color: #FFFFFF;

}

.pricing-item-header {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1;
}

.pricing-item-header h2 {
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
}

.pricing-item-body {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
}

.pricing-item-body h3 {
  font-weight: normal;
  font-size: 22px;
  line-height: 27px;
  color: #A9A9AA;
  padding: 0;
  margin: 0;
}

.pricing-item-body div {
  display: flex;
  gap: 12px;
}


.pricings-items-footers button {
  width: 100%;
  background: #ffffff;
  color: #0b0641;
  border-radius: 12px;
  border: none;
  padding: 12px;
  font-size: 22px;
  line-height: 28px;
  font-weight: bold;
}

.pricings-items-footers .pricings {
  font-weight: bold;
  font-size: 32px;
  line-height: 100px;
}

@media (max-width: 700px) {
  .pricing-container {
    flex-direction: column;
  }
}



.home-img {
  width: 40%;
  display: flex;
  align-items: center;
  text-align: center;

}



@keyframes wave {
  0% {
    background-position-x: 0;
  }

  0% {
    background-position-x: -250rem;
  }
}

.wave.wave-2 {
  opacity: 0.5;
  animation-delay: 0.2s;
  animation-direction: reverse;
}

.wave.wave-3 {
  opacity: 0.7;
  animation-duration: 5s;
}



@media (max-width: 500px) {
  .home-img {
    width: 90%;
    display: flex;
    align-items: center;
    text-align: center;
  }
}


.blob {
  position: absolute;
  width: 500px;
  height: 500px;
  background: linear-gradient(180deg,
      rgba(47, 184, 255, 0.42) 31.77%,
      #5c9df1 100%);
  mix-blend-mode: color-dodge;
  -webkit-animation: move 25s infinite alternate;
  animation: move 25s infinite alternate;
  transition: 1s cubic-bezier(0.07, 0.8, 0.16, 1);
}

.blob:hover {
  width: 520px;
  height: 520px;
  -webkit-filter: blur(30px);
  filter: blur(30px);
  box-shadow:
    inset 0 0 0 5px rgba(255, 255, 255, 0.6),
    inset 100px 100px 0 0px #fa709a,
    inset 200px 200px 0 0px #784ba8,
    inset 300px 300px 0 0px #2b86c5;
}


@media (max-width: 500px) {
  .blob {
    position: fixed;
    background: linear-gradient(180deg,
        rgba(47, 184, 255, 0.42) 31.77%,
        #5c9df1 100%);
    mix-blend-mode: color-dodge;
    -webkit-animation: move 25s infinite alternate;
    animation: move 25s infinite alternate;
    transition: 1s cubic-bezier(0.07, 0.8, 0.16, 1);
  }
}

@-webkit-keyframes move {
  from {
    transform: translate(-100px, -50px) rotate(-90deg);
    border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
  }

  to {
    transform: translate(500px, 100px) rotate(-10deg);
    border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
  }
}

@keyframes move {
  from {
    transform: translate(-100px, -50px) rotate(-90deg);
    border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
  }

  to {
    transform: translate(500px, 100px) rotate(-10deg);
    border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
  }
}


a,
a:active,
a:focus {
  color: #333;
  text-decoration: none;
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: .2s;
  -ms-transition-duration: .2s;
  -moz-transition-duration: .2s;
  -webkit-transition-duration: .2s;
  -o-transition-duration: .2s;
}

.rnd {
  color: #6317c8;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

.section-head {
  margin-bottom: 60px;
}

.section-head h4 {
  position: relative;
  padding: 0;
  color: #f91942;
  line-height: 1;
  letter-spacing: 0.3px;
  font-size: 34px;
  font-weight: 700;
  text-align: center;
  text-transform: none;
  margin-bottom: 30px;
}

.section-head h4:before {
  content: '';
  width: 60px;
  height: 3px;
  background: #f91942;
  position: absolute;
  left: 0px;
  bottom: -10px;
  right: 0;
  margin: 0 auto;
}

.section-head h4 span {
  font-weight: 700;
  padding-bottom: 5px;
  color: #2f2f2f
}

p.service_text {
  color: #cccccc !important;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
}

.section-head p,
p.awesome_line {
  color: #818181;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
}

.extra-text {
  font-size: 34px;
  font-weight: 700;
  color: #2f2f2f;
  margin-bottom: 25px;
  position: relative;
  text-transform: none;
}

.extra-text::before {
  content: '';
  width: 60px;
  height: 3px;
  background: #f91942;
  position: absolute;
  left: 0px;
  bottom: -10px;
  right: 0;
  margin: 0 auto;
}

.extra-text span {
  font-weight: 700;
  color: #f91942;
}

.item {
  background: #fff;
  text-align: center;
  padding: 30px 25px;
  -webkit-box-shadow: 0 0px 25px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0px 25px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  border: 5px solid rgba(0, 0, 0, 0.07);
  margin-bottom: 30px;
  -webkit-transition: all .5s ease 0;
  transition: all .5s ease 0;
  transition: all 0.5s ease 0s;
}

.item:hover {
  background: rgb(113 21 195);
  box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: all .5s ease 0;
  transition: all .5s ease 0;
  transition: all 0.5s ease 0s;
}

.item:hover .item,
.item:hover span.icon {
  background: #fff;
  border-radius: 10px;
  -webkit-transition: all .5s ease 0;
  transition: all .5s ease 0;
  transition: all 0.5s ease 0s;
}

.item:hover h6,
.item:hover p {
  color: #fff;
  -webkit-transition: all .5s ease 0;
  transition: all .5s ease 0;
  transition: all 0.5s ease 0s;
}

.item .icon {
  font-size: 40px;
  margin-bottom: 25px;
  color: #f91942;
  width: 90px;
  height: 90px;
  line-height: 96px;
  border-radius: 50px;
}

.item .feature_box_col_one {
  background: lightblue;
  color: #f91942
}

.item .feature_box_col_two {
  background: pink;
  color: #f91942
}

.item .feature_box_col_three {
  background: lightgreen;
  color: #f91942
}

.item .feature_box_col_four {
  background: rgba(0, 108, 255, 0.15);
  color: #f91942
}

.item .feature_box_col_five {
  background: rgba(146, 39, 255, 0.15);
  color: #f91942
}

.item .feature_box_col_six {
  background: rgba(23, 39, 246, 0.15);
  color: #f91942
}

.item p {
  font-size: 15px;
  line-height: 26px;
}

.item h6 {
  margin-bottom: 20px;
  color: #2f2f2f;
}

.mission p {
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 28px;
  font-weight: 500;
}

.mission i {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #f91942;
  border-radius: 50%;
  color: #fff;
  font-size: 25px;
}

.mission .small-text {
  margin-left: 10px;
  font-size: 13px;
  color: #666;
}

.skills {
  padding-top: 0px;
}

.skills .prog-item {
  margin-bottom: 25px;
}

.skills .prog-item:last-child {
  margin-bottom: 0;
}

.skills .prog-item p {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 10px;
}

.skills .prog-item .skills-progress {
  width: 100%;
  height: 10px;
  background: #e0e0e0;
  border-radius: 20px;
  position: relative;
}

.skills .prog-item .skills-progress span {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #f91942;
  width: 10%;
  border-radius: 10px;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.skills .prog-item .skills-progress span:after {
  content: attr(data-value);
  position: absolute;
  top: -5px;
  right: 0;
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  background: rgba(0, 0, 0, 0.9);
  padding: 3px 7px;
  border-radius: 30px;
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Catamaran', sans-serif;
  line-height: 1.6;
  color: #333;
  font-size: 1.1rem;
}

h1,
h2,
h3,
h4 {
  line-height: 1.3;
}

a {
  color: #444;
  text-decoration: none;
}

ul {
  list-style: none;
}


.section-a {
  margin: 0rem 0;
}

.section-a .container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
  align-items: center;
  justify-content: center;
}

.section-a h1 {
  font-size: 4rem;
  color: var(--primary-color);
}

.section-a p {
  margin: 1rem 0;
}


@media (max-width: 700px) {
  .section-a .container {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .section-a .container div:first-child {
    order: 2;
  }

  .section-a .container div:nth-child(2) {
    order: -1;
  }

  .section-a img {
    width: 80%;
    margin: auto;
  }
}